<template>
  <div>
    <div class="container-title mt-4">
      <div>MIS NOTAS</div>
    </div>
    <div class="d-flex mb-4 mr-2 mt-2 justify-content-end">
      <span class="select-text mr-2"
        ><strong>Filtrar notas por periodo:</strong>
      </span>
      <div>
        <b-form-select
          id="input-period"
          class="select-form"
          v-model="select_period"
          :options="options"
          @change="getStudentsResults()"
        ></b-form-select>
      </div>
    </div>
    <b-table-simple bordered small responsive hover class="mt-2">
      <b-thead>
        <b-tr>
          <b-th class="primary-cell-student">{{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}</b-th>
          <b-th class="primary-cell-student">Nota Ponderada</b-th>
          <b-th class="primary-cell-student">{{
            $getVisibleNames("evaluations2.evaluation", false, "Evaluación")
          }}</b-th>
          <b-th class="primary-cell-student">Nota</b-th>
          <b-th class="primary-cell-student">Ponderacion</b-th>
          <b-th class="primary-cell-student">{{
            $getVisibleNames("evaluations2.test", false, "Instrumento")
          }}</b-th>
          <b-th class="primary-cell-student">Puntaje Obtenido</b-th>
          <b-th class="primary-cell-student">Ponderacion</b-th>
          <b-th
            v-if="showFeedbackColumn()"
            class="primary-cell-student noprint"
            v-b-tooltip.v-secondary.top.noninteractive="`Retroalimentación`"
            >R...</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- Flujo normal cuando hay secciones -->
        <template v-for="(section, index1) in students_results">
          <!-- Condicion cuando no hay evaluaciones -->
          <template v-if="section.evaluatee_evaluations.length == 0">
            <b-tr :key="'section' + index1">
              <b-td class="secondary-cell-student">
                {{ section.matter_name }}
              </b-td>
              <b-td class="secondary-cell-student"> N/A </b-td>
              <b-td class="secondary-cell-student">
                No hay
                {{
                  $getVisibleNames(
                    "evaluations2.evaluation",
                    true,
                    "Evaluaciones"
                  )
                }}
              </b-td>
              <b-td class="secondary-cell-student"> N/A </b-td>
              <b-td class="secondary-cell-student"> N/A </b-td>
              <b-td class="secondary-cell-student">
                No hay
                {{
                  $getVisibleNames("evaluations2.test", false, "Instrumento")
                }}
              </b-td>
              <b-td class="secondary-cell-student"> N/A </b-td>
              <b-td class="secondary-cell-student"> N/A </b-td>
              <b-td
                v-if="showFeedbackColumn()"
                class="secondary-cell-student noprint"
              >
              </b-td>
            </b-tr>
          </template>

          <!-- Flujo normal cuando hay evaluaciones -->
          <template
            v-for="(evaluation, index2) in section.evaluatee_evaluations"
          >
            <!-- Condicion cuando no hay instrumentos -->
            <template v-if="evaluation.evaluatee_tests.length == 0">
              <b-tr :key="'section' + index1 + 'evaluation' + index2">
                <b-td
                  v-if="index2 == 0"
                  :rowspan="getRowEvaluation(section.evaluatee_evaluations)"
                  class="secondary-cell-student"
                >
                  {{ section.matter_name }}
                </b-td>
                <b-td
                  v-if="index2 == 0"
                  :rowspan="getRowEvaluation(section.evaluatee_evaluations)"
                  class="secondary-cell-student"
                >
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Nota de la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{
                      section.final_grade != null
                        ? parseFloat(section.final_grade.toFixed(1))
                        : section.manual_grade != null
                        ? parseFloat(section.manual_grade.toFixed(1))
                        : parseFloat(section.automated_grade.toFixed(1))
                    }}
                  </span>
                </b-td>
                <b-td class="secondary-cell-student">
                  <span
                    class="div-href-test"
                    @click="redirectToEvaluationStudent(evaluation.evaluation)"
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Ir a la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{ evaluation.evaluation_name }}
                  </span>
                </b-td>
                <b-td class="secondary-cell-student">
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Nota de la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{
                      evaluation.final_grade != null
                        ? evaluation.final_grade
                        : evaluation.manual_grade != null
                        ? evaluation.manual_grade
                        : evaluation.automated_grade | Round
                    }}
                  </span>
                </b-td>
                <b-td class="secondary-cell-student">
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Ponderación de la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{ evaluation.evaluation_weighing }}%
                  </span>
                </b-td>
                <b-td class="secondary-cell-student">
                  No hay
                  {{
                    $getVisibleNames("evaluations2.test", true, "Instrumentos")
                  }}
                </b-td>
                <b-td class="secondary-cell-student"> N/A </b-td>
                <b-td class="secondary-cell-student"> N/A </b-td>
                <b-td
                  v-if="showFeedbackColumn()"
                  class="secondary-cell-student noprint"
                ></b-td>
              </b-tr>
            </template>

            <!-- Flujo normal cuando hay test -->
            <template v-for="(test, index3) in evaluation.evaluatee_tests">
              <b-tr
                :key="
                  'section' + index1 + 'evaluation' + index2 + 'test' + index3
                "
              >
                <b-td
                  v-if="index2 == 0 && index3 == 0"
                  :rowspan="getRowEvaluation(section.evaluatee_evaluations)"
                  class="secondary-cell-student"
                >
                  {{ section.matter_name }}
                </b-td>
                <b-td
                  v-if="index2 == 0 && index3 == 0"
                  :rowspan="getRowEvaluation(section.evaluatee_evaluations)"
                  class="secondary-cell-student"
                >
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Nota final de la ${$getVisibleNames(
                        'mesh.egressprofilematter',
                        false,
                        'Asignatura'
                      )}`
                    "
                  >
                    {{
                      section.final_grade != null
                        ? parseFloat(section.final_grade.toFixed(1))
                        : section.manual_grade != null
                        ? parseFloat(section.manual_grade.toFixed(1))
                        : parseFloat(section.automated_grade.toFixed(1))
                    }}
                  </span>
                </b-td>
                <b-td
                  v-if="index3 == 0"
                  :rowspan="getRowTest(evaluation.evaluatee_tests)"
                  class="secondary-cell-student"
                >
                  <span
                    class="div-href-test"
                    @click="
                      redirectToEvaluationStudent(
                        evaluation.evaluation,
                        test.id
                      )
                    "
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Ir a la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{ evaluation.evaluation_name }}
                  </span>
                </b-td>
                <b-td
                  v-if="index3 == 0"
                  :rowspan="getRowTest(evaluation.evaluatee_tests)"
                  class="secondary-cell-student"
                >
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Nota de la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{
                      evaluation.final_grade != null
                        ? evaluation.final_grade
                        : evaluation.manual_grade != null
                        ? evaluation.manual_grade
                        : evaluation.automated_grade | Round
                    }}
                  </span>
                </b-td>
                <b-td
                  v-if="index3 == 0"
                  :rowspan="getRowTest(evaluation.evaluatee_tests)"
                  class="secondary-cell-student"
                >
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Ponderación de la ${$getVisibleNames(
                        'evaluations2.evaluation',
                        false,
                        'Evaluación'
                      )}`
                    "
                  >
                    {{ evaluation.evaluation_weighing }}%
                  </span>
                </b-td>
                <b-td class="secondary-cell-student">
                  {{ test.test_name }}
                </b-td>
                <b-td class="secondary-cell-student text-center">
                  <div>
                    <span
                      v-b-tooltip.v-secondary.top.noninteractive="
                        `Puntaje Obtenido`
                      "
                    >
                      {{
                        test.final_score != null
                          ? test.final_score
                          : test.manual_score != null
                          ? test.manual_score
                          : test.automated_score | Round
                      }}
                    </span>
                    /
                    <span
                      v-b-tooltip.v-secondary.top.noninteractive="
                        `Puntaje total del ${$getVisibleNames(
                          'evaluations2.test',
                          false,
                          'Instrumento'
                        )}`
                      "
                    >
                      {{ test.test_max_score }}
                    </span>
                  </div>
                </b-td>
                <b-td class="secondary-cell-student">
                  <span
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Ponderación del ${$getVisibleNames(
                        'evaluations2.test',
                        false,
                        'Instrumento'
                      )}`
                    "
                  >
                    {{ test.test_weighing }}%
                  </span>
                </b-td>
                <b-td
                  v-if="showFeedbackColumn()"
                  class="secondary-cell-student noprint"
                >
                  <div>
                    <template
                      v-if="
                        (test.feedback != null && test.feedback.length > 0) ||
                        (test.evaluator_attachments != null &&
                          test.evaluator_attachments.length > 0)
                      "
                    >
                      <b-button
                        variant="none"
                        size="sm"
                        class="p-0 btn-feedback"
                        v-b-tooltip.v-secondary.top.noninteractive="
                          `Retroalimentación de la ${$getVisibleNames(
                            'evaluations2.evaluation',
                            false,
                            'Evaluación'
                          )}`
                        "
                        @click="$bvModal.show(`feedback-instrument-${test.id}`)"
                        ><b-icon
                          icon="chat-left-text-fill"
                          class="icon-btn-feedback"
                        ></b-icon
                      ></b-button>
                    </template>
                  </div>
                  <b-modal
                    :id="`feedback-instrument-${test.id}`"
                    hide-footer
                    size="xl"
                  >
                    <b-table-simple
                      bordered
                      v-if="
                        test.evaluator_attachments != null &&
                        test.evaluator_attachments.length > 0
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th class="th-modal-feedback text-center"
                            >ADJUNTO DEL EVALUADOR</b-th
                          >
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td>
                            <FileContainer
                              :Files="test.evaluator_attachments"
                              :allows_crud="false"
                            ></FileContainer>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <b-table-simple bordered>
                      <b-thead>
                        <b-tr>
                          <b-th class="th-modal-feedback text-center"
                            >RETROALIMENTACIÓN DE LA
                            {{
                              $getVisibleNames(
                                "evaluations2.evaluation",
                                false,
                                "Evaluación"
                              ).toUpperCase()
                            }}</b-th
                          >
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-td>
                            <div
                              v-html="test.feedback"
                              class="rich-text-content"
                            ></div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-modal>
                </b-td>
              </b-tr>
            </template>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudentGradeTable",
  components: {
    FileContainer: () => import("@/components/reusable/FileContainer"),
  },
  props: {},
  data() {
    return {
      students_results: [],
      periods_student: [],
      grade_ranges: [],
      select_period: null,
      options: [{ value: null, text: "Periodos Actuales" }],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
    totalGrades() {
      if (!this.institution) return [];
      let count_grades = [];
      this.students_results.forEach((x) => {
        count_grades.push(
          x.final_grade != null
            ? x.final_grade
            : x.manual_grade != null
            ? x.manual_grade
            : x.automated_grade != null
            ? x.automated_grade
            : this.institution.min_grade
        );
        x.evaluatee_evaluations.forEach((y) => {
          count_grades.push(
            y.final_grade != null
              ? y.final_grade
              : y.manual_grade != null
              ? y.manual_grade
              : y.automated_grade != null
              ? y.automated_grade
              : this.institution.min_grade
          );
        });
      });
      return count_grades;
    },
  },
  methods: {
    getGradeRange() {
      this.$restful.Get("/evaluations2/grade-range/").then((response) => {
        this.grade_ranges = response;
      });
    },
    gradeRangeBackground(grade) {
      for (let range of this.grade_ranges) {
        if (range.include_start_grade && range.include_finish_grade) {
          if (grade >= range.start_grade && grade <= range.finish_grade) {
            return range.color;
          }
        } else if (range.include_start_grade && !range.include_finish_grade) {
          if (grade >= range.start_grade && grade < range.finish_grade) {
            return range.color;
          }
        } else if (!range.include_start_grade && range.include_finish_grade) {
          if (grade > range.start_grade && grade <= range.finish_grade) {
            return range.color;
          }
        } else {
          if (grade > range.start_grade && grade < range.finish_grade) {
            return range.color;
          }
        }
      }
      return null; // Retorna null si no encuentra ningún rango que coincida.
    },
    showFeedbackColumn() {
      return this.students_results.some((x) => {
        return x.evaluatee_evaluations.some((y) => {
          return y.evaluatee_tests.some((z) => {
            return z.feedback != null && z.feedback.length > 0;
          });
        });
      });
    },
    optionsPeriods(periodsFromApi) {
      this.options = [{ value: null, text: "Periodos Actuales" }];

      periodsFromApi.forEach((x) => {
        this.options.push({ value: x.id, text: x.name });
      });
    },
    redirectToEvaluationStudent(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "EvaluateeView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    getRowEvaluation(evaluations) {
      let row = 0;
      evaluations.forEach((x) => {
        if (x.evaluatee_tests.length == 0) {
          row++;
        } else row += x.evaluatee_tests.length;
      });
      return row;
    },
    getRowTest(tests) {
      if (tests.length == 0) return 1;
      else return tests.length;
    },
    getStudentsResults() {
      if (
        this.user &&
        this.user.groups.includes(7) &&
        this.select_period == null
      ) {
        this.$restful
          .Get(
            `/evaluations2/student_sections_evaluations_tests/?student=${this.user.id}`
          )
          .then((response) => {
            this.students_results = response;
          });
      } else if (
        this.user &&
        this.user.groups.includes(7) &&
        this.select_period != null
      ) {
        this.$restful
          .Get(
            `/evaluations2/student_sections_evaluations_tests/?student=${this.user.id}&period=${this.select_period}`
          )
          .then((response) => {
            this.students_results = response;
          });
      }
    },
    getPeriodsStudent() {
      if (this.user && this.user.groups.includes(7)) {
        this.$restful
          .Get(
            `/evaluations2/student_periods_with_sections/?student=${this.user.id}`
          )
          .then((response) => {
            this.periods_student = response;
            this.optionsPeriods(this.periods_student);
          });
      }
    },
  },
  mounted() {},
  watch: {},
  created() {
    this.getGradeRange();
    this.getStudentsResults();
    this.getPeriodsStudent();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.btn-feedback {
  width: 20px;
  height: 20px;
}
.icon-btn-feedback {
  padding: 0.15rem !important;
}
.th-modal-feedback {
  background: var(--kl-menu-color) !important ;
  color: white;
}
.select-text {
  align-self: center !important;
}
.select-form {
  min-width: 300px;
}
.container-title {
  font-weight: bold;
  font-size: var(--secondary-title-font-size);
  text-align: center;
}
.primary-cell-student {
  vertical-align: middle;
  border: 1px solid black !important;
  background-color: var(--kl-menu-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
  text-align: center;
}
.secondary-cell-student {
  border: 1px solid black !important;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.div-href-test {
  color: #007bff;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
</style>